import React, { Component } from 'react'
import DynamicComponent from "./DynamicComponent"
import SbEditable from 'storyblok-react'
import CloudinaryVideo from './cloudinaryvideoembed'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Footer from './Footer'

class VideoGallery extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const blok = this.props.blok
    const content =
      blok.body &&
      blok.body.map(childBlok => <DynamicComponent blok={childBlok} key={childBlok._uid}/>)
    return (
      <SbEditable content={blok}>
        {(blok.BackgroundVideo) ?
          <div className="bg-video">
            <CloudinaryVideo
              publicId={`ykk/${blok.BackgroundVideo}`}
              class_list="cover-bg"
              autoPlay={true}
            />
          </div>
          : ''}

        <div id="scroller">
          <div class="video-gallery">
            { content }
          </div>
          <Footer />
        </div>
      </SbEditable>
    )
  }

}

export default VideoGallery
